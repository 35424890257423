import React from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Box, Divider, Drawer, Hidden, List, Typography, makeStyles } from '@material-ui/core'
import {
  HelpCircle as HelpCircleIcon,
  Home as HomeIcon,
  Info as InfoIcon,
  Settings as SettingsIcon,
  User as UserIcon,
  UserCheck as UserCheckIcon,
  Layers as LayersIcon,
  LogOut as LogoutIcon,
  Upload as UploadIcon,
  Paperclip as PaperclipIcon
} from 'react-feather'
import { FormattedMessage } from 'react-intl'
import { accountService } from 'src/services'
import NavItem from './NavItem'

const items = [
  {
    href: '/app/access-requests',
    icon: UserCheckIcon,
    title: 'navigation.accessRequests',
    roles: ['Admin']
  },
  {
    href: '/app/administrations',
    icon: HomeIcon,
    title: 'navigation.administrations',
    roles: ['User']
  },
  {
    href: '/app/bulk-upload',
    icon: UploadIcon,
    title: 'navigation.bulkUpload',
    roles: ['User']
  },
  {
    href: '/app/manage-administrations',
    icon: LayersIcon,
    title: 'navigation.administrations',
    roles: ['Admin']
  },
  {
    href: '/app/account',
    icon: UserIcon,
    title: 'navigation.account',
    roles: ['Admin', 'User']
  },
  {
    href: '/app/settings',
    icon: SettingsIcon,
    title: 'navigation.settings',
    roles: ['Admin']
  },
  {
    href: '/app/assets',
    icon: PaperclipIcon,
    title: 'navigation.assets',
    roles: ['Admin']
  }
]

const secondaryItems = [
  {
    href: '/app/help',
    icon: HelpCircleIcon,
    title: 'navigation.help'
  },
  {
    href: '/app/terms-and-conditions',
    icon: InfoIcon,
    title: 'navigation.termsAndConditions'
  }
]

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 210,
    top: 96,
    height: 'calc(100% - 96px)',
    zIndex: 0
  }
}))

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles()
  const location = useLocation()
  const user = accountService.userValue
  const { role } = user

  const lang = location.pathname.split('/')[1]
  const activePath = location.pathname.replace(`/${lang}`, '')

  const [enabledItems, setEnabledItems] = React.useState([])
  React.useEffect(() => {
    const filteredItems = items.filter(item => item.roles.includes(role))
    setEnabledItems(filteredItems)
  }, [role])

  React.useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Typography className={classes.name} color="textPrimary" variant="h5">
          {user.firstName} {user.lastName}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {enabledItems.map(item => (
            <NavItem
              href={item.href}
              key={item.title}
              title={<FormattedMessage id={item.title} />}
              icon={item.icon}
              active={activePath === item.href}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
      <Box p={2}>
        <List>
          <Hidden lgUp>
            <NavItem
              onClick={accountService.logout}
              href="/login"
              title={<FormattedMessage id="header.logout" />}
              icon={LogoutIcon}
            />
          </Hidden>
          {secondaryItems.map(item => (
            <NavItem
              href={item.href}
              key={item.title}
              title={<FormattedMessage id={item.title} />}
              icon={item.icon}
              active={activePath === item.href}
            />
          ))}
        </List>
      </Box>
    </Box>
  )

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  )
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
}

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
}

export default NavBar
