import React from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import { Card, CardContent, CardHeader, Chip, Divider, IconButton } from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid'
import { useSnackbar } from 'notistack'
import { useConfirm } from 'material-ui-confirm'
import { assetsService } from 'src/services'
import { injectIntl, FormattedMessage } from 'react-intl'
import { CloudDownload, Delete, AspectRatio } from '@material-ui/icons'
import SplitButton from './SplitButton'
//

const AssetList = ({ intl, reloadAssets, assets, setLoading, loading, setHelpPreviewFile }) => {
  const { enqueueSnackbar } = useSnackbar()
  const confirm = useConfirm()

  const [selected, setSelected] = React.useState([])

  const handleSelectionChange = selection => {
    setSelected(selection.rowIds)
  }

  const handleHelpPreview = () => {
    const rowSelected = selected.length === 1 && assets.length > selected[0] ? assets[selected[0]] : null
    if (rowSelected && rowSelected.isHelpFile) {
      setHelpPreviewFile(rowSelected.name)
    } else {
      setHelpPreviewFile(null)
    }
  }

  const submitTag = (tagSelected) => {
    const fileNameSelected = selected.length === 1 && assets.length > selected[0] ? assets[selected[0]].name : null
    if (fileNameSelected) {
      setLoading(true)
      assetsService.postTag(fileNameSelected, tagSelected).then(async () => {
        await reloadAssets()
        enqueueSnackbar(intl.formatMessage({ id: 'assets.list.tagChangedSuccessful' }), { variant: 'success' })
      })
    }
  }

  const confirmDelete = (fileName) => {
    confirm({
      title: intl.formatMessage({ id: 'assets.list.deleteConfirmationsTitle' }),
      description: intl.formatMessage({ id: 'assets.list.deleteConfirmationsDescription' })
    }).then(() => {
      setLoading(true)
      enqueueSnackbar(`Deleting document ${fileName}`, { variant: 'error' })

      assetsService.delete(fileName).then(async () => {
        await reloadAssets()
        enqueueSnackbar(intl.formatMessage({ id: 'assets.list.deleteSuccessful' }), { variant: 'info' })
      })
    }).catch(() => {
      setLoading(false)
    })
  }

  const handleDeleteSelected = () => {
    const fileNameSelected = selected.length === 1 && assets.length > selected[0] ? assets[selected[0]].name : null
    if (fileNameSelected) {
      confirmDelete(fileNameSelected)
    }
  }

  const columns = [
    { field: 'name', headerName: intl.formatMessage({ id: 'assets.list.name' }), width: 300 },
    {
      field: "tag",
      headerName: "Label",
      renderCell: (cellValues) => {
        if (cellValues && !!cellValues.row) {
          if (cellValues.row.isHelpFile) {
            return (
              <>
                <Chip size="small" label="Help" />
                <IconButton onClick={handleHelpPreview}>
                  <AspectRatio style={{ marginRight: '8px' }} />
                </IconButton>
              </>
            );
          }
          if (cellValues.row.isDocNL) {
            return (
              <Chip size="small" color="primary" label="DOC NL" />
            );
          } if (cellValues.row.isDocFR) {
            return (
              <Chip size="small" color="primary" label="DOC FR" />
            );
          } if (cellValues.row.isTecNL) {
            return (
              <Chip size="small" color="primary" label="T&C NL" />
            );
          } if (cellValues.row.isTecFR) {
            return (
              <Chip size="small" color="primary" label="T&C FR" />
            );
          }
        }
        return <></>
      },
      width: 110
    },
    {
      field: "download",
      headerName: "Download",
      renderCell: (cellValues) => {
        const fileName = cellValues && cellValues.row && cellValues.row.name
        return <IconButton onClick={() => assetsService.download(fileName)}>
          <CloudDownload style={{ marginRight: '8px' }} color="primary" />
        </IconButton>
      },
      width: 80
    },
    {
      field: "delete",
      headerName: "Delete",
      renderCell: () => {
        return <IconButton onClick={handleDeleteSelected}>
          <Delete color="error" />
        </IconButton>
      },
      width: 80
    },
  ]

  return (
    <Card>
      <CardHeader
        title={intl.formatMessage({ id: 'assets.list.title' })}
        subheader={intl.formatMessage({ id: 'assets.list.subtitle' })}
      />
      <Divider />
      <CardContent style={{ overflow: 'auto' }}>
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ minHeight: '450px', flexGrow: 1 }}>

            <Container maxWidth="xl">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {assets.length ? (
                    <DataGrid
                      rows={assets}
                      rowsPerPageOptions={[10, 20, 50, 100]}
                      columns={columns}
                      pageSize={10}
                      headerHeight={42}
                      rowHeight={34}
                      disableMultipleSelection
                      autoHeight
                      loading={loading}
                      onSelectionChange={handleSelectionChange}
                    />
                  ) : (
                    !loading &&
                    <Typography>
                      <FormattedMessage id="assets.list.noElements" />
                    </Typography>
                  )}
                  {assets.length ? (
                    <Box display="flex" justifyContent="flex-end">
                      <SplitButton
                        submitTag={submitTag}
                        disabled={!selected.length || loading || (assets && assets[selected[0]].isHelpFile)}
                      />
                    </Box>
                    // <Box display="flex" justifyContent="flex-end">
                    //   <Button
                    //     className={classes.button}
                    //     onClick={handleFRDocSelected}
                    //     color="primary"
                    //     variant="contained"
                    //     disabled={!selected.length || loading || (assets && assets[selected[0]].isHelpFile)}
                    //   >
                    //     <FormattedMessage id="assets.list.frDocButton" />
                    //   </Button>
                    //   <Button
                    //     className={classes.button}
                    //     onClick={handleNLDocSelected}
                    //     color="primary"
                    //     variant="contained"
                    //     disabled={!selected.length || loading || (assets && assets[selected[0]].isHelpFile)}
                    //   >
                    //     <FormattedMessage id="assets.list.nlDocButton" />
                    //   </Button>
                    // </Box>
                  ) : null}
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
      </CardContent >
    </Card >
  )
}

AssetList.propTypes = {
  intl: PropTypes.object,
  reloadAssets: PropTypes.func,
  assets: PropTypes.array,
  setLoading: PropTypes.func,
  loading: PropTypes.bool,
  setHelpPreviewFile: PropTypes.func
}

export default injectIntl(AssetList)
