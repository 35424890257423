import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import clsx from 'clsx'
import * as Yup from 'yup'
import { Formik, Field } from 'formik'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Divider,
  Grid,
  Typography,
  CircularProgress,
  makeStyles
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
//
import { settingsService } from 'src/services'

const useStyles = makeStyles({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
})

const dateRegex = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01]) [0-9]{2}:[0-9]{2}$/

const Availability = ({ className, intl, ...rest }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = React.useState(true)
  const [availability, setAvailability] = React.useState({
    id: false,
    startDate: '2021-01-01 00:00',
    endDate: '2021-03-31 00:00'
  })

  React.useEffect(() => {
    settingsService
      .getByKey('availability')
      .then(res => {
        // eslint-disable-next-line no-underscore-dangle
        const id = res._id
        const { startDate, endDate } = res.value
        setAvailability({ id, startDate, endDate })
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  return loading ? (
    <div className={classes.alert} style={{ width: '100%', textAlign: 'center' }}>
      <CircularProgress />
      <Typography color="textPrimary" variant="h4">
        <FormattedMessage id="global.loading" />
      </Typography>
    </div>
  ) : (
    <Formik
      initialValues={{
        startDate: availability.startDate,
        endDate: availability.endDate
      }}
      validationSchema={Yup.object().shape({
        startDate: Yup.string()
          .matches(dateRegex, intl.formatMessage({ id: 'validations.date' }))
          .required(intl.formatMessage({ id: 'validations.date' })),
        endDate: Yup.string()
          .matches(dateRegex, intl.formatMessage({ id: 'validations.date' }))
          .required(intl.formatMessage({ id: 'validations.date' }))
      })}
      onSubmit={({ startDate, endDate }, { setSubmitting }) => {
        setSubmitting(true)

        if (!availability.id) {
          settingsService
            .create({ key: 'availability', value: { startDate, endDate } })
            .then(() => {
              setSubmitting(false)
              enqueueSnackbar(intl.formatMessage({ id: 'messages.success' }), { variant: 'success' })
            })
            .catch(() => {
              setSubmitting(false)
              enqueueSnackbar(intl.formatMessage({ id: 'messages.error' }), { variant: 'error' })
            })
        } else {
          settingsService
            .update(availability.id, { key: 'availability', value: { startDate, endDate } })
            .then(() => {
              setSubmitting(false)
              enqueueSnackbar(intl.formatMessage({ id: 'messages.success' }), { variant: 'success' })
            })
            .catch(() => {
              setSubmitting(false)
              enqueueSnackbar(intl.formatMessage({ id: 'messages.error' }), { variant: 'error' })
            })
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form onSubmit={handleSubmit} className={clsx(classes.root, className)} {...rest}>
          <Card>
            <CardHeader
              subheader={intl.formatMessage({ id: 'settings.availabilityInfo' })}
              title={intl.formatMessage({ id: 'settings.availability' })}
            />
            <Divider />
            <CardContent>
              <Grid container spacing={6} wrap="wrap">
                <Grid className={classes.item} item md={4} sm={6} xs={12}>
                  <Typography color="textPrimary" gutterBottom variant="h6">
                    <FormattedMessage id="settings.defineAvailabilityDates" />
                  </Typography>

                  <Box mt={2}>
                    <Field
                      component={TextField}
                      error={Boolean(touched.startDate && errors.startDate)}
                      helperText={touched.startDate && errors.startDate}
                      label={intl.formatMessage({ id: 'settings.startDate' })}
                      id="startDate"
                      name="startDate"
                      type="datetime"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className={classes.textField}
                      value={values.startDate}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Box>

                  <Box mt={2}>
                    <Field
                      component={TextField}
                      error={Boolean(touched.endDate && errors.endDate)}
                      helperText={touched.endDate && errors.endDate}
                      label={intl.formatMessage({ id: 'settings.endDate' })}
                      id="endDate"
                      name="endDate"
                      type="datetime"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.endDate}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button color="primary" disabled={isSubmitting} size="large" type="submit" variant="contained">
                <FormattedMessage id="buttons.save" />
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  )
}

Availability.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.object
}

export default injectIntl(Availability)
