import React from 'react'
import { makeStyles } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
//
import Page from 'src/components/Page'
import { assetsService } from 'src/services'
import UploadAsset from './UploadAsset'
import AssetList from './AssetList'
import HelpContent from '../../pages/HelpView/HelpContent'

const helpFiles = [
  'global.en.md',
  'user.en.md',
  'global.de.md',
  'user.de.md',
  'global.fr.md',
  'user.fr.md',
  'global.nl.md',
  'user.nl.md',
]

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(3)
  }
}))

const AssetsView = () => {
  const classes = useStyles()

  const [assets, setAssets] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [helpPreviewFile, setHelpPreviewFile] = React.useState()

  const reloadAssets = async () => {
    assetsService.getAll()
      .then(response => {
        const files = response
        setAssets(files.map((file, index) => {
          const { name, tag } = file
          return {
            id: index,
            name,
            tag: tag || (helpFiles.includes(name) ? "Help" : undefined),
            isHelpFile: helpFiles.includes(name),
            isDocNL: tag && tag.includes("DOC NL"),
            isDocFR: tag && tag.includes("DOC FR"),
            isTecNL: tag && tag.includes("T&C NL"),
            isTecFR: tag && tag.includes("T&C FR")
          }
        }))
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  React.useEffect(() => {
    reloadAssets()
  }, [])

  return (
    <Page className={classes.root} title="Assets">
      <Container maxWidth="lg">
        <Grid container spacing={6} wrap="wrap">
          <Grid className={classes.item} item sm={9} xs={12}>
            <AssetList reloadAssets={reloadAssets} assets={assets} setLoading={setLoading} loading={loading} setHelpPreviewFile={setHelpPreviewFile} />
          </Grid>
          <Grid className={classes.item} item sm={3} xs={12}>
            <UploadAsset reloadAssets={reloadAssets} />
          </Grid>
          <Grid className={classes.item} item xs={12}>
            <HelpContent isHelpPreview helpPreviewFile={helpPreviewFile} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

export default AssetsView
