import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Box, Button, Container, Link, TextField, Typography, makeStyles } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import Alert from '@material-ui/lab/Alert'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import CircularProgress from '@material-ui/core/CircularProgress'
//
import Page from 'src/components/Page'
import { accountService, settingsService } from 'src/services'
import { LocalizedLink, useLocalizedNavigate } from 'src/i18n'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  alert: {
    marginBottom: theme.spacing(2)
  }
}))

const LoginView = ({ intl }) => {
  const classes = useStyles()
  const navigate = useLocalizedNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [available, setAvailable] = React.useState(true)
  const { executeRecaptcha } = useGoogleReCaptcha()

  React.useEffect(() => {
    settingsService
      .getAvailability()
      .then(res => {
        const { startDate, endDate } = res.value
        const now = Date.now()
        const begin = Date.parse(startDate)
        const end = Date.parse(endDate)
        if (now < begin || now > end) {
          setAvailable(false)
        }
      })
      .catch(() => setAvailable(false))
  }, [])

  return (
    <Page className={classes.root} title="Login">
      <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
        <Container maxWidth="sm" spacing="1">
          {!available && (
            <Alert className={classes.alert} severity="warning">
              <Typography color="textPrimary" variant="h6">
                <FormattedMessage id="login.closedLine1" />
                <br />
                <FormattedMessage id="login.closedLine2" />{' '}
                <a href="mailto:sec.cvps@premier.fed.be">sec.cvps@premier.fed.be</a>
              </Typography>
            </Alert>
          )}
        </Container>
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email(intl.formatMessage({ id: 'validations.email' }))
                .max(255)
                .required(intl.formatMessage({ id: 'validations.field' })),
              password: Yup.string()
                .max(255)
                .required(intl.formatMessage({ id: 'validations.field' }))
            })}
            onSubmit={async ({ email, password }, { setSubmitting }) => {
              const captcha = await executeRecaptcha('login')

              const bypass2fa = process.env.REACT_APP_BYPASS_2FA === 'true'

              accountService
                .login(email, password, captcha)
                .then(user => {
                  if (user.mfaEnabled && !bypass2fa) {
                    navigate('/login/2fa', { replace: true, state: { user } })
                  } else {
                    accountService.finalizeLogin(user)
                    navigate('/app/account', { replace: true })
                  }
                })
                .catch(err => {
                  setSubmitting(false)
                  if (err === 'Application is offline') {
                    enqueueSnackbar(intl.formatMessage({ id: 'login.closedError' }), {
                      variant: 'error',
                      autoHideDuration: 15000
                    })
                  } else {
                    enqueueSnackbar(intl.formatMessage({ id: 'login.invalidDetails' }), { variant: 'error' })
                  }
                })
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography color="textPrimary" variant="h2">
                    <FormattedMessage id="login.title" />
                  </Typography>
                  <Typography color="textSecondary" gutterBottom variant="body2">
                    <FormattedMessage id="login.description" />
                  </Typography>
                </Box>

                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label={<FormattedMessage id="login.email" />}
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label={<FormattedMessage id="login.password" />}
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    startIcon={isSubmitting ? <CircularProgress size={14} color="secondary" /> : null}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    <FormattedMessage id="login.submit" />
                  </Button>
                </Box>
                <Typography color="textSecondary" variant="body1">
                  <FormattedMessage id="login.noAccount" />{' '}
                  <Link component={LocalizedLink} to="/register" variant="h6">
                    <FormattedMessage id="login.signup" />
                  </Link>
                </Typography>
                <Typography color="textSecondary" variant="body1">
                  <FormattedMessage id="login.resetPasswordText" />{' '}
                  <Link component={LocalizedLink} to="/forgot-password" variant="h6">
                    <FormattedMessage id="login.resetPasswordButton" />
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  )
}

export default injectIntl(LoginView)

LoginView.propTypes = {
  intl: PropTypes.object
}
