import React from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton';
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid'
import { useSnackbar } from 'notistack'
import { useConfirm } from 'material-ui-confirm'
import { administrationsAccountsService, aggregationsService } from 'src/services'
import getAdministrationsTitle from 'src/utils/getAdministrationsTitle'
import { injectIntl, FormattedMessage } from 'react-intl'
//
import Page from 'src/components/Page'

const moment = require('moment');

const formatDate = value => value && moment(value).format('DD/MM/YYYY')

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  button: {
    marginRight: '10px'
  }
}))

const AccessRequestsView = ({ intl }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const confirm = useConfirm()
  const [loading, setLoading] = React.useState(true)
  const [accessRequests, setAccessRequests] = React.useState([])
  const [selected, setSelected] = React.useState([])

  const columns = [
    { field: 'administrationCode', headerName: intl.formatMessage({ id: 'dataGrid.admCode' }), width: 80 },
    {
      field: 'administrationName',
      headerName: intl.formatMessage({ id: 'dataGrid.admName' }),
      width: 260,
      renderCell: params => (
        <Tooltip placement="top" title={params.value}>
          <Box>{params.value}</Box>
        </Tooltip>
      )
    },
    { field: 'firstName', headerName: intl.formatMessage({ id: 'dataGrid.firstName' }), width: 130 },
    { field: 'lastName', headerName: intl.formatMessage({ id: 'dataGrid.lastName' }), width: 130 },
    {
      field: 'email',
      headerName: intl.formatMessage({ id: 'dataGrid.email' }),
      width: 150,
      renderCell: params => (
        <Tooltip placement="top" title={params.value}>
          <Box>{params.value}</Box>
        </Tooltip>
      )
    },
    { field: 'totalUsers', headerName: intl.formatMessage({ id: 'dataGrid.users' }), width: 80, type: 'number', },
    {
      field: 'lastLoginDate',
      headerName: intl.formatMessage({ id: 'dataGrid.lastLogin' }),
      width: 120,
      type: 'date',
      renderCell: params => {
        if (params.value) {
          const formattedDate = formatDate(params.value)
          return <Tooltip placement="top" title={formattedDate} >
            <Box>{formattedDate}</Box>
          </Tooltip >
        }
        return null
      }
    },
    {
      field: 'lastLoginEmail',
      headerName: intl.formatMessage({ id: 'dataGrid.lastLoginEmail' }),
      width: 150,
      renderCell: params => params.value && (
        <Tooltip placement="top" title={params.value}>
          <Box>{params.value}</Box>
        </Tooltip>
      )
    }
  ]

  const mappedRequests = record => ({
    // eslint-disable-next-line no-underscore-dangle
    id: record._id,
    administrationCode: record.administration && record.administration.code,
    administrationName:
      record.administration && record.administration.name && getAdministrationsTitle(record.administration.name),
    lastName: record.account.lastName,
    firstName: record.account.firstName,
    email: record.account.email,
    lastLoginDate: record.lastLoginDate,
    lastLoginEmail: record.lastLoginEmail,
    totalUsers: record.totalUsers
  })

  React.useEffect(() => {
    setLoading(true)
    aggregationsService.getAccountAdministrationsExtended('PENDING').then(records => {
      const requests = records.map(mappedRequests)
      setAccessRequests(requests)
      setLoading(false)
    })
  }, [])

  const handleSelectionChange = selection => {
    setSelected(selection.rowIds)
  }

  const handleGrantAccess = () => {
    confirm({
      title: intl.formatMessage({ id: 'confirmations.title' }),
      description: intl.formatMessage({ id: 'confirmations.description' })
    }).then(() => {
      setLoading(true)
      enqueueSnackbar(`Granting access to ${selected.length} requests`, { variant: 'info' })
      const payload = {
        accountsAdministrations: selected.map(id => ({ id, status: 'APPROVED' }))
      }

      administrationsAccountsService.updateAccountsAdministrations(payload).then(() => {
        aggregationsService.getAccountAdministrationsExtended('PENDING').then(records => {
          const requests = records.map(mappedRequests)
          setAccessRequests(requests)
          setLoading(false)
          enqueueSnackbar(intl.formatMessage({ id: 'messages.success' }), { variant: 'success' })
        })
      })
    })
  }

  const handleRefuseAccess = () => {
    confirm({
      title: intl.formatMessage({ id: 'confirmations.title' }),
      description: intl.formatMessage({ id: 'confirmations.description' })
    }).then(() => {
      setLoading(true)
      enqueueSnackbar(`Refusing access to ${selected.length} requests`, { variant: 'info' })
      administrationsAccountsService.deleteAccountsAdministrations({ accountsAdministrations: selected }).then(() => {
        aggregationsService.getAccountAdministrationsExtended('PENDING').then(records => {
          const requests = records.map(mappedRequests)
          setAccessRequests(requests)
          setLoading(false)
          enqueueSnackbar(intl.formatMessage({ id: 'messages.success' }), { variant: 'success' })
        })
      })
    })
  }

  return (
    <Page className={classes.root} title="Access Requests">
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography color="textPrimary" gutterBottom variant="h3">
              Access Requests
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div style={{ display: 'flex', height: '100%' }}>
              <div style={{ minHeight: '450px', flexGrow: 1 }}>
                {accessRequests.length ? (
                  <DataGrid
                    rows={accessRequests}
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    columns={columns}
                    pageSize={10}
                    headerHeight={42}
                    rowHeight={34}
                    checkboxSelection
                    autoHeight
                    loading={loading}
                    onSelectionChange={handleSelectionChange}
                  />
                ) : (
                  <Typography>
                    {loading ? <Skeleton /> : <FormattedMessage id="messages.noRequests" />}
                  </Typography>
                )}
              </div>
            </div>
            {accessRequests.length ? (
              <Box display="flex" justifyContent="flex-end">
                <Button
                  className={classes.button}
                  onClick={handleGrantAccess}
                  color="primary"
                  variant="contained"
                  disabled={!selected.length || loading}
                >
                  <FormattedMessage id="buttons.grantAccess" />
                </Button>
                <Button
                  className={classes.button}
                  onClick={handleRefuseAccess}
                  variant="contained"
                  style={{ color: selected.length ? 'red' : 'gray' }}
                  disabled={!selected.length || loading}
                >
                  <FormattedMessage id="buttons.refuseAccess" />
                </Button>
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

AccessRequestsView.propTypes = {
  intl: PropTypes.object
}

export default injectIntl(AccessRequestsView)
