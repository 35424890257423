import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { CircularProgress, makeStyles } from '@material-ui/core'
import { injectIntl, FormattedMessage } from 'react-intl'
import { useParams } from 'react-router'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'

//
import Page from 'src/components/Page'
import getAdministrationTitle from 'src/utils/getAdministrationsTitle/getAdministrationTitle'
import { aggregationsService, administrationsService } from 'src/services'
import ProcessedRecordsGrid from './ProcessedRecordsGrid'
import ErrorsRecordsGrid from './ErrorsRecordsGrid'
import FileUploadForm from './FileUploadForm'
import PreviousUploads from './PreviousUploads'

const sortForEndYear = (a, b) => {
  if (!a.endYear) return -1
  if (!b.endYear) return 1
  return (a.endYear - b.endYear)
}

const currentYear = new Date(Date.now()).getFullYear()

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}))

const AdministrationView = ({ intl }) => {
  const classes = useStyles()
  const [upload, setUpload] = useState()
  const [administration, setAdministration] = useState()
  const { code } = useParams()

  const [processing, setProcessing] = React.useState(false)

  const [authorizationCheck, setAuthorizationCheck] = React.useState("CHECKING")
  const [authorizationEndYear, setAuthorizationEndYear] = React.useState(currentYear)
  const [isClosedForSubmission, setClosedForSubmission] = React.useState(false)

  useEffect(() => {
    let cancel = false;

    const checkAuthentication = async () => {
      if (administration && administration.code) {
        const response = await aggregationsService.administrationAuthorizationByCode(administration.code)

        if (cancel) return

        const isAutorized = !!response && response.userAccessGranted
        if (isAutorized) {
          setAuthorizationCheck("AUTHORIZED")
          setAuthorizationEndYear(response.endYear)
          setClosedForSubmission(response.isClosedForSubmission)
        } else {
          setAuthorizationCheck("UNAUTHORIZED")
        }
      }
    }

    checkAuthentication()
    return () => { cancel = true }
  }, [administration, code])

  useEffect(() => {
    administrationsService.getByQuery({ code }).then(records => {
      const record = records.sort(sortForEndYear)[0]
      setAdministration(record)
    })
  }, [code])

  useEffect(() => {
    if (upload && upload.status === 'PROCESSING') {
      setProcessing(true)
    }
  }, [upload])

  const handleProcessingStatus = (completed = false) => {
    if (processing && completed) {
      setProcessing(false)
    }
  }

  return (
    <Page className={classes.root} title={intl.formatMessage({ id: 'navigation.administrations' })}>
      {authorizationCheck !== "AUTHORIZED" &&
        <div className={classes.alert} style={{ width: '100%', textAlign: 'center' }}>
          <CircularProgress />
        </div>
      }
      {authorizationCheck === "AUTHORIZED" &&
        <Container maxWidth="xl">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {administration && (
                <Typography color="textPrimary" gutterBottom variant="h3">
                  {getAdministrationTitle(administration.name)}
                </Typography>
              )}
            </Grid>
            <Grid item xs={9}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography style={{ marginBottom: '2rem' }} color="textPrimary" gutterBottom variant="h5">
                    <FormattedMessage id="administrationsView.csvUpload" />
                  </Typography>
                  {/* eslint-disable-next-line no-underscore-dangle */}
                  <FileUploadForm administrationId={administration._id} onUpload={setUpload} authorizationEndYear={authorizationEndYear} isClosedForSubmission={isClosedForSubmission} />
                </Grid>
                <Grid item xs={12}>
                  {upload && upload.status === 'PROCESSED' && <ProcessedRecordsGrid upload={upload.id} />}
                  {upload && upload.status === 'ERROR' && <ErrorsRecordsGrid upload={upload} />}
                  {upload && upload.status === 'PROCESSING' && processing && (
                    <Alert className={classes.alert} severity="warning">
                      <AlertTitle>
                        <FormattedMessage id="administrationsView.processingTitle" />
                      </AlertTitle>
                      <Typography color="textPrimary" variant="h4">
                        <FormattedMessage id="administrationsView.processingMessage" />
                      </Typography>
                    </Alert>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <PreviousUploads newUpload={upload} handleProcessingStatus={handleProcessingStatus} />
            </Grid>
          </Grid>
        </Container>
      }
    </Page>
  )
}

export default injectIntl(AdministrationView)

AdministrationView.propTypes = {
  intl: PropTypes.object
}
