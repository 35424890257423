import React from 'react'
import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Tooltip from '@material-ui/core/Tooltip'
import TextField from '@material-ui/core/TextField'
import { IconButton, makeStyles } from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid'
import Button from '@material-ui/core/Button'
import { injectIntl, FormattedMessage } from 'react-intl'
//
import Page from 'src/components/Page'
import { aggregationsService, settingsService } from 'src/services'
import getAdministrationsTitle from 'src/utils/getAdministrationsTitle'
import { useSnackbar } from 'notistack'
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import { CloudDownload } from '@material-ui/icons'
import { useSearchParams } from 'react-router-dom'
import { Formik } from 'formik'
import * as Yup from 'yup'
import EditAdministration from './EditAdministration'
import CreateAdministration from './CreateAdministration'
import UsersAdministration from './UsersAdministration'
import DeleteAdministration from './DeleteAdministration'
import UploadsAdministration from './UploadsAdministration'

const moment = require('moment');

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  button: {
    fontSize: '.6rem',
    textDecoration: 'underline'
  }
}))

const getAdministrations = async () => {
  return aggregationsService.getAdministrationsExtended().then(res => {
    return res.map(admin => {
      return {
        // eslint-disable-next-line no-underscore-dangle
        id: admin._id,
        code: admin.code,
        name: getAdministrationsTitle(admin.name),
        lastLoginDate: admin.lastLoginDate,
        lastLoginEmail: admin.lastLoginEmail,
        lastUpload: admin.lastUpload,
        lastDownload: admin.lastDownload,
        totalUsers: admin.totalUsers,
        endYear: admin.endYear > 0 ? admin.endYear : undefined
      }
    })
  })
}

const formatDate = value => value && moment(value).format('DD/MM/YYYY')

const defaultSortAdministrations = (a, b) => +a.code - +b.code

const AccessRequestsView = ({ intl }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const [searchParams, setSearchParams] = useSearchParams();

  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = React.useState(true)
  const [administrations, setAdministrations] = React.useState([])
  const [selected, setSelected] = React.useState('')
  const [dialog, setDialog] = React.useState('')
  const [displayUploads, setDisplayUploads] = React.useState(false)

  const setCode = code => {
    if (code) {
      searchParams.set('code', code)
    } else {
      searchParams.delete('code')
    }

    setSearchParams(searchParams)
  }

  const setName = (name) => {
    if (name) {
      searchParams.set('name', name)
    } else {
      searchParams.delete('name')
    }
    setSearchParams(searchParams)
  }

  const refreshAdmins = async () => {
    setLoading(true)

    const code = searchParams.get('code')
    const name = searchParams.get('name')
    const nameRegExp = name ? new RegExp(name, 'i') : null

    getAdministrations()
      .then(admins => admins.filter(admin => {
        let exists = false
        if (code && nameRegExp) {
          if (admin.code.includes(code) && admin.name.toLowerCase().search(nameRegExp) >= 0) {
            exists = true
          }
        } else if (code && !nameRegExp) {
          if (admin.code.includes(code)) {
            exists = true
          }
        } else if (!code && nameRegExp) {
          if (admin.name.toLowerCase().search(nameRegExp) >= 0) {
            exists = true
          }
        } else {
          exists = true
        }

        return exists
      })
      )
      .then(admins => setAdministrations(admins.sort(defaultSortAdministrations)))
      .then(() => setLoading(false))
  }

  const handleCloseDialog = () => {
    setSelected('')
    setDialog('')
    refreshAdmins()
  }

  const handleEdit = adminId => {
    setSelected(adminId)
    setDialog('edit')
  }

  const handleUsers = adminId => {
    setSelected(adminId)
    setDialog('users')
  }

  const handleCreate = () => {
    setDialog('create')
  }

  const handleUploads = adminId => {
    setSelected(adminId)
    setDialog('uploads')
  }

  const handleExport = async () => {
    const instant = moment().format('YYYY-MM-DD-hh_mm_ss')
    await aggregationsService.exportAdministrationsExtended(`administrations-${instant}.csv`)
  }

  const handleDelete = adminId => {
    setSelected(adminId)
    setDialog('delete')
  }

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
    enqueueSnackbar('copied!', {
      variant: 'secondary'
    })
  }

  React.useEffect(() => {
    settingsService.getDisplayUploads().then(res => {
      const { value, isCurrentSessionEnabled } = res
      setDisplayUploads(value.displayUploads && isCurrentSessionEnabled)
      refreshAdmins()
    })
  }, [])

  const columns = [
    { field: 'code', headerName: intl.formatMessage({ id: 'dataGrid.admCode' }), width: 70 },
    {
      field: 'name',
      headerName: intl.formatMessage({ id: 'dataGrid.admName' }),
      width: 360,
      renderCell: params => (
        <Tooltip placement="top" title={params.value}>
          <Box>{params.value}</Box>
        </Tooltip>
      )
    },
    {
      field: 'lastLoginDate',
      headerName: intl.formatMessage({ id: 'dataGrid.lastLogin' }),
      width: 120,
      type: 'date',
      renderCell: params => {
        if (params.value) {
          const formattedDate = formatDate(params.value)
          return <Tooltip placement="top" title={formattedDate} >
            <Box>{formattedDate}</Box>
          </Tooltip >
        }
        return null
      }
    },
    {
      field: 'lastLoginEmail', headerName: intl.formatMessage({ id: 'dataGrid.lastLoginEmail' }), width: 70,
      renderCell: params => params.value && <Tooltip placement="top" title={params.value}>
        <IconButton aria-label="copy to clipboard" onClick={() => copyToClipboard(params.value)}>
          <AlternateEmailIcon />
        </IconButton>
      </Tooltip>
    },
    {
      field: 'lastUpload',
      headerName: intl.formatMessage({ id: 'dataGrid.lastUpload' }),
      width: 120,
      type: 'date',
      renderCell: params => displayUploads ? <Grid
        container
        direction="column"
        justifycontent="center"
        alignItems="center"
      >
        <Button
          onClick={() => {
            handleUploads(params.row.id)
          }}
          size="small"
          className={classes.button}
        >
          {(params.value) && <Tooltip placement="top" title={formatDate(params.value)}>
            < Box > {formatDate(params.value)}</Box>
          </Tooltip >
          }
          {!(params.value) && <FormattedMessage id="buttons.uploads" />}
        </Button>
      </Grid > : <Tooltip placement="top" title={formatDate(params.value)}>
        < Box > {formatDate(params.value)}</Box>
      </Tooltip >
    },
    {
      field: 'lastDownload',
      headerName: intl.formatMessage({ id: 'dataGrid.lastDownload' }),
      width: 110,
      type: 'date',
      renderCell: params => {
        if (params.value) {
          const formattedDate = formatDate(params.value)
          return <Tooltip placement="top" title={formattedDate} >
            <Box>{formattedDate}</Box>
          </Tooltip >
        }
        return null
      }
    },
    {
      field: 'totalUsers',
      headerName: intl.formatMessage({ id: 'dataGrid.users' }),
      width: 90,
      renderCell: params => {
        const numberOfUsers = params.value
        let numberOfUsersLabel
        if (!numberOfUsers) {
          numberOfUsersLabel = 'no users'
        } else if (numberOfUsers === 1) {
          numberOfUsersLabel = '1 user'
        } else {
          numberOfUsersLabel = `${numberOfUsers} users`
        }
        return <Tooltip placement="top" title={numberOfUsersLabel}>
          <Box>
            <Button size="small" className={classes.button} onClick={() => {
              handleUsers(params.row.id)
            }}>{numberOfUsersLabel}</Button>
          </Box>
        </Tooltip>
      }
    },
    {
      field: 'endYear',
      headerName: intl.formatMessage({ id: 'dataGrid.endYear' }),
      width: 110,
      renderCell: params => params.value && <Tooltip placement="top" title={params.value} >
        <Box style={{ color: 'red' }}>{params.value}</Box>
      </Tooltip >
    },
    {
      field: 'actions',
      headerName: intl.formatMessage({ id: 'dataGrid.actions' }),
      width: 110,
      renderCell: params => (
        <Grid
          container
          direction="column"
          justifycontent="center"
          alignItems="center"
        >
          <Button
            onClick={() => {
              handleEdit(params.row.id)
            }}
            size="small"
            className={classes.button}
          >
            <FormattedMessage id="buttons.update" />
          </Button>
          <Button
            style={{ color: 'red' }}
            onClick={() => handleDelete(params.row.id)}
            size="small"
            className={classes.button}
          >
            <FormattedMessage id="buttons.delete" />
          </Button>
        </Grid>
      )
    }
  ]

  return (
    <Page className={classes.root} title="Access Requests">
      {dialog === 'edit' && selected && (
        <EditAdministration
          open
          id={selected}
          handleClose={handleCloseDialog}
        />
      )}
      {dialog === 'create' && (
        <CreateAdministration
          open
          handleClose={handleCloseDialog}
        />
      )}
      {dialog === 'delete' && (
        <DeleteAdministration
          open
          id={selected}
          handleClose={handleCloseDialog}
        />
      )}
      {dialog === 'users' && selected && (
        <UsersAdministration
          open
          id={selected}
          handleClose={handleCloseDialog}
        />
      )}
      {dialog === 'uploads' && selected && (
        <UploadsAdministration
          open
          id={selected}
          handleClose={handleCloseDialog}
        />
      )}
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography color="textPrimary" gutterBottom variant="h3">
              <FormattedMessage id="administrationsView.manageTitle" />
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Formik
              onSubmit={async ({ code, name }) => {
                try {
                  setLoading(true)
                  setCode(code)
                  setName(name)
                  refreshAdmins()
                } catch (err) {
                  setLoading(true)
                }
              }}
              initialValues={{
                code: searchParams.get('code') || '',
                name: searchParams.get('name') || ''
              }}
              validationSchema={Yup.object().shape({
                code: Yup.string()
                  .min(0)
                  .max(4),
                name: Yup.string()
                  .max(160),
              })}>
              {({ handleSubmit, values, handleBlur, handleChange }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={1}>
                    <Grid item md={9} xs={12}>
                      <TextField name="code" id="filter-code" label="Filter by code" style={{ margin: 8 }}
                        value={values.code} onBlur={handleBlur} onChange={handleChange} />
                      <TextField name="name" id="filter-name" label="Filter by name" style={{ margin: 8 }}
                        value={values.name} onBlur={handleBlur} onChange={handleChange} />
                      <Button size="small" color="primary" variant="outlined" type="submit" style={{ marginTop: '22px' }}>
                        <FormattedMessage id="buttons.filter" />
                      </Button>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <Box display="flex" justifyContent="flex-end">
                        <Button style={{ marginLeft: '15px' }} onClick={handleExport} color="primary" variant="outlined"
                          startIcon={<CloudDownload />}
                        >
                          <FormattedMessage id="buttons.exportcsv" />
                        </Button>
                        <Button style={{ marginLeft: '15px' }} onClick={handleCreate} color="primary" variant="contained">
                          <FormattedMessage id="buttons.createNew" />
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Grid>

          <Grid item xs={12}>
            <div style={{ display: 'flex', height: '100%' }}>
              <div style={{ minHeight: '450px', flexGrow: 1 }}>
                {administrations.length || loading ? (
                  <DataGrid
                    disableSelectionOnClick
                    rowsPerPageOptions={[10, 20, 50, 100, 250]}
                    rows={administrations}
                    columns={columns}
                    pageSize={10}
                    autoHeight
                    loading={loading}
                  />
                ) : (
                  <Typography>
                    <FormattedMessage id="administrationsView.noAdministrations" />
                  </Typography>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

export default injectIntl(AccessRequestsView)

AccessRequestsView.propTypes = {
  intl: PropTypes.object
}
