import config from 'src/utils/config'
import fetchWrapper from 'src/utils/fetch'

const baseUrl = `${config.apiUrl}/administrations`

function getAll() {
  return fetchWrapper.get(baseUrl)
}

function getByQuery(query) {
  return fetchWrapper.get(baseUrl, query)
}

function getById(id) {
  return fetchWrapper.get(`${baseUrl}/${id}`)
}

function create(params) {
  return fetchWrapper.post(baseUrl, params)
}

function update(id, params) {
  return fetchWrapper.put(`${baseUrl}/${id}`, params)
}

function forceUpdate(id, params) {
  return fetchWrapper.patch(`${baseUrl}/${id}`, params)
}

// eslint-disable-next-line no-underscore-dangle
function _delete(id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`)
}

export default {
  getAll,
  getByQuery,
  getById,
  create,
  update,
  forceUpdate,
  delete: _delete
}
