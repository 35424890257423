import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Grid,
  Container,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography
} from '@material-ui/core'
import getAdministrationsTitle from 'src/utils/getAdministrationsTitle'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import CircularProgress from '@material-ui/core/CircularProgress'
import { injectIntl, FormattedMessage } from 'react-intl'
//
import { administrationsService, uploadService } from 'src/services'
import groupBy from 'src/utils/groupBy'

const getUploads = async id => {
  return uploadService.getByQuery({ administration: id })
    .then(results => results
      .filter(upload => upload.status === "PROCESSED")
      .map(upload => {
        return {
          // eslint-disable-next-line no-underscore-dangle
          id: upload._id,
          ...upload
        }
      })
    )
}

function formattedDate(date) {
  const mm = date.getMonth() + 1
  const dd = date.getDate()
  const hh = date.getHours()
  const min = date.getMinutes()

  const day = [(dd > 9 ? '' : '0') + dd, (mm > 9 ? '' : '0') + mm, date.getFullYear()].join('-')
  const time = [(hh > 9 ? '' : '0') + hh, (min > 9 ? '' : '0') + min].join(':')

  return `${day} ${time}`
}

function formatNumber(num) {
  return num && num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}


const UploadsAdministration = ({ id, open, handleClose }) => {
  const [administration, setAdministration] = React.useState(null)
  const [uploads, setUploads] = React.useState([])
  const [loading, setLoading] = React.useState(true)

  const refreshUploads = () => {
    setLoading(true)
    getUploads(id).then(newUploads => {
      const res = newUploads
        .map(upload => {
          // eslint-disable-next-line no-underscore-dangle
          return { id: upload._id, date: formattedDate(new Date(upload.created)), ...upload }
        })
        .sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())

      if (!!res && res.length > 0) {
        const resultsGroupedBy = groupBy(res, 'year').sort((a, b) => b[0].year - a[0].year)
        setUploads(resultsGroupedBy)
      }
      setLoading(false)
    })
  }

  React.useEffect(() => {
    administrationsService
      .getById(id)
      .then(async admin => {
        setAdministration(admin)
        refreshUploads()
      })
      .catch(() => {
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <Dialog fullWidth maxWidth="lg" onClose={handleClose} open={open}>
      <DialogTitle>
        <FormattedMessage id="administrationsView.checkUploadsTitle" />
        {!!administration && <>
          <br />
          {`${administration.code} - ${getAdministrationsTitle(administration.name)}`}
        </>}
      </DialogTitle>
      <Container>
        <Grid container xl spacing={2}>
          <Grid item xs={12}>
            {loading ? (
              <Box>
                <CircularProgress color="inherit" size={20} />
                <FormattedMessage id="global.loading" />
              </Box>
            ) : null}
            {!loading && (uploads.length === 0 ? (<Typography>
              <FormattedMessage id="administrationsView.noUploadsPresent" />
            </Typography>
            ) : (
              <List component="nav" aria-label="main">
                {uploads.map(groupedByYear => (
                  <React.Fragment key={groupedByYear[0].year}>
                    <ListSubheader>{groupedByYear[0].year}</ListSubheader>
                    {groupedByYear.map(upload => (
                      <ListItem key={upload.id} style={{ padding: '2px' }} onClick={() => uploadService.download(upload.id, upload.filePath)} button>
                        <ListItemIcon style={{ minWidth: '32px' }}>
                          <DescriptionOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={<Box>{upload.fullPath}</Box>}
                          secondary={
                            <small>{upload.date}-{' '}
                              <FormattedMessage
                                id="administrationsView.numberRows"
                                values={{ number: formatNumber(upload.size) }}
                              />
                            </small>
                          }
                        />
                      </ListItem>
                    ))}
                  </React.Fragment>
                ))}
              </List>
            ))}
          </Grid>

          <Grid item xs={12} style={{ padding: '10px' }}>
            <Box display="flex" justifyContent="flex-end">
              <Button onClick={handleClose} color="primary" variant="text">
                <FormattedMessage id="buttons.close" />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Dialog >
  )
}

export default injectIntl(UploadsAdministration)

UploadsAdministration.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired
}
