import React from 'react'
import { Container, Grid, makeStyles } from '@material-ui/core'
import Page from 'src/components/Page'
import Availability from './Availability'
import Security from './Security'
import InfoMessage from './InfoMessage'
import TermsAndConditions from './TermsAndConditions'
import DisplayUploads from './DisplayUploads'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}))

const SettingsView = () => {
  const classes = useStyles()

  return (
    <Page className={classes.root} title="Settings">
      <Container maxWidth="lg">
        <Grid container spacing={6} wrap="wrap">
          <Grid className={classes.item} item sm={6} xs={12}>
            <Availability />
          </Grid>
          <Grid className={classes.item} item sm={6} xs={12}>
            <Security />
          </Grid>
          <Grid className={classes.item} item sm={6} xs={12}>
            <InfoMessage />
          </Grid>
          <Grid className={classes.item} item sm={6} xs={12}>
            <TermsAndConditions />
          </Grid>
          <Grid className={classes.item} item sm={6} xs={12}>
            <DisplayUploads />
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

export default SettingsView
