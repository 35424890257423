import config from 'src/utils/config'
import fetchWrapper from 'src/utils/fetch'

const baseUrl = `${config.apiUrl}/settings`

function getAll() {
  return fetchWrapper.get(baseUrl)
}

function getByKey(key) {
  return fetchWrapper.get(baseUrl, { key })
}

function getAvailability() {
  return fetchWrapper.get(`${baseUrl}/availability`)
}

function getInfoMessage() {
  return fetchWrapper.get(`${baseUrl}/info-message`)
}

function getTermsAndConditions() {
  return fetchWrapper.get(`${baseUrl}/terms-and-conditions`)
}

function getDisplayUploads() {
  return fetchWrapper.get(`${baseUrl}/display-uploads`)
}

function create(params) {
  return fetchWrapper.post(baseUrl, params)
}

function update(id, params) {
  return fetchWrapper.put(`${baseUrl}/${id}`, params).then(setting => {
    return setting
  })
}
export default {
  getAll,
  getAvailability,
  getInfoMessage,
  getTermsAndConditions,
  getDisplayUploads,
  getByKey,
  create,
  update
}
