import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { makeStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Link from '@material-ui/core/Link'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useSnackbar } from 'notistack'
//
import Page from 'src/components/Page'
import { accountService } from 'src/services'
import { LocalizedLink, useLocalizedNavigate } from 'src/i18n'
import config from 'src/utils/config'
import AdministrationsSelect from 'src/components/AdministrationsSelect'
import { passwordRegex } from 'src/utils/validations'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  dropdown: {
    minWidth: '200px'
  }
}))

const RegisterView = ({ intl }) => {
  const classes = useStyles()
  const lang = intl.locale
  const { enqueueSnackbar } = useSnackbar()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const navigate = useLocalizedNavigate()

  const [registered, setRegistered] = React.useState(false)

  return (
    <Page className={classes.root} title="Register">
      <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
        <Container maxWidth="md" style={{ height: '100%' }}>
          <Formik
            initialValues={{
              title: '',
              email: '',
              firstName: '',
              lastName: '',
              telephoneNumber: '',
              password: '',
              confirmPassword: '',
              administrations: []
            }}
            validationSchema={Yup.object().shape({
              title: Yup.string().required(intl.formatMessage({ id: 'validations.field' })),
              email: Yup.string()
                .email(intl.formatMessage({ id: 'validations.email' }))
                .max(160)
                .required(intl.formatMessage({ id: 'validations.field' })),
              firstName: Yup.string()
                .max(100)
                .required(intl.formatMessage({ id: 'validations.field' })),
              lastName: Yup.string()
                .max(100)
                .required(intl.formatMessage({ id: 'validations.field' })),
              telephoneNumber: Yup.string()
                .max(64)
                .required(intl.formatMessage({ id: 'validations.field' }))
                .required(intl.formatMessage({ id: 'validations.field' })),
              password: Yup.string()
                .max(64)
                .required(intl.formatMessage({ id: 'validations.field' }))
                .matches(passwordRegex, intl.formatMessage({ id: 'validations.password' })),
              confirmPassword: Yup.string()
                .max(64)
                .required(intl.formatMessage({ id: 'validations.field' }))
                .oneOf([Yup.ref('password')], intl.formatMessage({ id: 'validations.passwordMatch' }))
            })}
            onSubmit={async (
              {
                title,
                email,
                firstName,
                lastName,
                password,
                confirmPassword,
                telephoneNumber,
                administrations: admins
              },
              { setSubmitting }
            ) => {
              const captcha = await executeRecaptcha('login')

              accountService
                .register({
                  title,
                  email,
                  firstName,
                  lastName,
                  password,
                  confirmPassword,
                  telephoneNumber,
                  lang,
                  administrations: admins,
                  captcha
                })
                .then(() => {
                  enqueueSnackbar(intl.formatMessage({ id: 'registration.success' }), {
                    variant: 'success',
                    autoHideDuration: 10000
                  })
                  setRegistered(true)
                  navigate('/login')
                })
                .catch(() => {
                  setSubmitting(false)
                  enqueueSnackbar(intl.formatMessage({ id: 'registration.error' }), {
                    variant: 'error',
                    autoHideDuration: 10000
                  })
                })
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography color="textPrimary" variant="h2">
                    <FormattedMessage id="registration.info1" />
                  </Typography>
                  <Typography color="textSecondary" gutterBottom variant="body2">
                    <FormattedMessage id="registration.info2" />
                  </Typography>
                </Box>
                <FormControl className={classes.dropdown}>
                  <InputLabel style={{ paddingLeft: '10px' }} id="title-label">
                    <FormattedMessage id="registration.title" />
                  </InputLabel>
                  <Select
                    label="Title"
                    labelId="title-label"
                    id="title"
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    variant="outlined"
                  >
                    <MenuItem value="Mr">
                      <FormattedMessage id="titles.Mr" />
                    </MenuItem>
                    <MenuItem value="Mrs">
                      <FormattedMessage id="titles.Mrs" />
                    </MenuItem>
                    <MenuItem value="Miss">
                      <FormattedMessage id="titles.Miss" />
                    </MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  error={Boolean(touched.firstName && errors.firstName)}
                  fullWidth
                  helperText={touched.firstName && errors.firstName}
                  label={intl.formatMessage({ id: 'registration.firstName' })}
                  margin="normal"
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.lastName && errors.lastName)}
                  fullWidth
                  helperText={touched.lastName && errors.lastName}
                  label={intl.formatMessage({ id: 'registration.lastName' })}
                  margin="normal"
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.telephoneNumber && errors.telephoneNumber)}
                  fullWidth
                  helperText={touched.telephoneNumber && errors.telephoneNumber}
                  label={intl.formatMessage({ id: 'registration.telephoneNumber' })}
                  margin="normal"
                  name="telephoneNumber"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.telephoneNumber}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label={intl.formatMessage({ id: 'registration.email' })}
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label={intl.formatMessage({ id: 'registration.password' })}
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                  fullWidth
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  label={intl.formatMessage({ id: 'registration.confirmPassword' })}
                  margin="normal"
                  name="confirmPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.confirmPassword}
                  variant="outlined"
                />
                <Box mt={3}>
                  <AdministrationsSelect
                    onChange={(e, value) => {
                      setFieldValue(
                        'administrations',
                        // eslint-disable-next-line no-underscore-dangle
                        value.map(admin => admin._id)
                      )
                    }}
                  />
                </Box>
                <Box alignItems="center" display="flex" ml={1} mt={1}>
                  <Typography color="textSecondary" variant="body2">
                    <FormattedMessage id="registration.notFoundInfo" />{' '}
                    <a href={`mailto:${config.supportMailbox}`}>{config.supportMailbox}</a>
                  </Typography>
                </Box>
                {registered || (
                  <Box my={2} mt={3}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      startIcon={isSubmitting ? <CircularProgress size={14} color="secondary" /> : null}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      <FormattedMessage id="buttons.signup" />
                    </Button>
                  </Box>
                )}
                <Typography color="textSecondary" variant="body1">
                  <FormattedMessage id="registration.haveAccount" />{' '}
                  <Link component={LocalizedLink} to="/login" variant="h6">
                    <FormattedMessage id="buttons.signin" />
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  )
}

export default injectIntl(RegisterView)

RegisterView.propTypes = {
  intl: PropTypes.object
}
