/* eslint-disable no-underscore-dangle */
import React from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Container,
  Dialog,
  DialogTitle,
  CircularProgress,
  Select,
  MenuItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  TextField
} from '@material-ui/core'
import BusinessIcon from '@material-ui/icons/Business'
import { Formik } from 'formik'
import { useSnackbar } from 'notistack'
import DialogActions from '@material-ui/core/DialogActions'
import { injectIntl, FormattedMessage } from 'react-intl'
//
import { administrationsService, aggregationsService } from 'src/services'
import { Alert, AlertTitle } from '@material-ui/lab'

const currentYear = new Date(Date.now()).getFullYear()
const yearOptions = Array.from(new Array(5), (v, i) => {
  return currentYear - i
})

const DeleteAdministration = ({ id, open, handleClose, intl }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [administration, setAdministration] = React.useState(null)
  const [approvedUsers, setApprovedUsers] = React.useState(null)
  const [pendingUsers, setPendingUsers] = React.useState(null)
  const [numDocumentsUplaoded, setNumDocumentsUplaoded] = React.useState(null)
  const [isUserActivityPresent, setUserActivityPresent] = React.useState(null)
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    administrationsService
      .getById(id)
      .then(admin => {
        setAdministration(admin)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [id])

  React.useEffect(() => {
    if (administration && administration.code) {
      setLoading(true)
      aggregationsService.administrationAuthorizationById(administration._id)
        .then(authorization => {
          /* eslint-disable no-shadow */
          const { approvedUsers, pendingUsers, numDocumentsUplaoded } = authorization
          setUserActivityPresent(!!(approvedUsers || pendingUsers || numDocumentsUplaoded))
          setApprovedUsers(approvedUsers)
          setPendingUsers(pendingUsers)
          setNumDocumentsUplaoded(numDocumentsUplaoded)
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    }
  }, [administration])

  const handleDelete = async () => {
    await administrationsService.delete(id)
    enqueueSnackbar(intl.formatMessage({ id: 'messages.administrationDeleted' }), {
      variant: 'error',
      autoHideDuration: 10000
    })
    handleClose()
  }

  const handleCloseYear = async (year) => {
    await administrationsService.update(id, { endYear: year })
    enqueueSnackbar(intl.formatMessage({ id: 'messages.administrationClosed' }), {
      variant: 'error',
      autoHideDuration: 10000
    })
    handleClose()
  }

  const handleReopen = async () => {
    await administrationsService.forceUpdate(id, { endYear: null })
    enqueueSnackbar(intl.formatMessage({ id: 'messages.administrationReopened' }), {
      variant: 'success',
      autoHideDuration: 10000
    })
    handleClose()
  }

  return (
    <Dialog fullWidth maxWidth="lg" onClose={handleClose} open={open}>
      <DialogTitle style={{ color: 'red' }} >
        <FormattedMessage id="administrationsView.deleteAdministration" />
      </DialogTitle>
      <Container>
        <Grid container xl>
          <Grid item xs={12}>
            {loading && (
              <div>
                <CircularProgress color="inherit" size={20} />
                <FormattedMessage id="global.loading" />
              </div>
            )}

            {!loading && !administration && <p>error loading data!</p>}

            {!loading && administration ? (
              <Formik
                onSubmit={async () => {
                }}
                initialValues={{
                  year: currentYear,
                  code: '',
                }}
                validationSchema={Yup.object().shape({
                  year: Yup.string()
                    .required(intl.formatMessage({ id: 'validations.field' }))
                    .test('year', 'Invalid Year', value => {
                      const date = new Date()
                      date.setYear(value)
                      // eslint-disable-next-line no-restricted-globals
                      return !isNaN(date.getTime())
                    }),
                })}
              >
                {({ handleSubmit, isSubmitting, values, touched, errors, handleBlur, handleChange }) => (
                  <form onSubmit={handleSubmit}>
                    <Card>
                      <CardContent>
                        <Grid
                          container
                          direction="row"
                          justifycontent="space-between"
                          alignItems="flex-end"
                          spacing={3}
                        >
                          <Grid item xs={12}>
                            <ListItemAvatar>
                              <Avatar>
                                <BusinessIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={`${administration.name.fr} / ${administration.name.nl}`} secondary={administration.code} />
                            {administration.endYear ? (<Alert severity="warning">
                              <AlertTitle>
                                <FormattedMessage id="administrationsView.alertDeletedTitle" />
                              </AlertTitle>
                              <Typography color="textPrimary" variant="h4">
                                <FormattedMessage id="administrationsView.alertDeletedText" values={{ year: administration.endYear }} />
                              </Typography>
                            </Alert>) : null
                            }
                          </Grid>

                          <Grid item xs={6}>
                            <Grid
                              container
                              direction="column"
                              justifycontent="flex-end"
                              alignItems="stretch"
                              spacing={2}
                            >
                              <Typography color="textSecondary" mb={2}>
                                <FormattedMessage id="administrationsView.deletedLogicallyText" />
                              </Typography>
                              <Box mb={2}>
                                <Select
                                  style={{ paddingTop: 5, paddingBottom: 5 }}
                                  fullWidth
                                  label={intl.formatMessage({ id: 'administrationsView.year' })}
                                  labelId="year-label"
                                  id="year"
                                  name="year"
                                  defaultValue={currentYear}
                                  onChange={handleChange}
                                  disabled={isSubmitting}
                                  renderinput={params => (
                                    <TextField
                                      {...params}
                                      label={intl.formatMessage({ id: 'administrationsView.year' })}
                                      variant="outlined"
                                      placeholder={intl.formatMessage({ id: 'administrationsView.selectDeleteYear' })}
                                    />
                                  )}
                                >
                                  <MenuItem name="" key="open" value="open"><i>open</i></MenuItem>
                                  {yearOptions.map(year => (
                                    <MenuItem name="" key={year} value={year}>
                                      {year}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </Box>
                              {(administration.endYear && values.year === 'open') ? (
                                <Button fullWidth onClick={() => handleReopen()} size="large" type="button" variant="contained" >
                                  <FormattedMessage id="buttons.reopen" />
                                </Button>
                              ) : (
                                <Button fullWidth onClick={() => handleCloseYear(values.year)} size="large" type="button" variant="contained" style={{ color: 'red' }}
                                  disabled={values.year === 'open'} >
                                  <FormattedMessage id="buttons.closeFromYear" />
                                </Button>
                              )}
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid
                              spacing={2}
                              container
                              direction="column"
                              justifycontent="flex-end"
                              alignItems="stretch"
                            >
                              <Typography color="textSecondary" mb={2}>
                                <FormattedMessage id="administrationsView.deletedPermanentlyText" />
                              </Typography>

                              {!!approvedUsers && <Typography color="textPrimary">
                                <FormattedMessage id="administrationsView.deletedApprovedUsersText" values={{ users: approvedUsers }} />
                              </Typography>
                              }
                              {!!pendingUsers && <Typography color="textPrimary">
                                <FormattedMessage id="administrationsView.deletedPendingUsersText" values={{ users: pendingUsers }} />
                              </Typography>
                              }
                              {!!numDocumentsUplaoded && <Typography color="textPrimary">
                                <FormattedMessage id="administrationsView.deletedNumDocumentsUplaodedText" values={{ documents: numDocumentsUplaoded }} />
                              </Typography>
                              }
                              <Grid
                                mt={2}
                                container
                                direction="row"
                                justifycontent="center"
                                alignItems="baseline"
                              >
                                {isUserActivityPresent && <TextField
                                  // error={Boolean(touched.code && errors.code)}
                                  style={{ paddingTop: 5, paddingBottom: 5 }}
                                  fullWidth
                                  helperText={touched.code && errors.code}
                                  label={intl.formatMessage({ id: 'administrationsView.permanentDeleteCode' })}
                                  name="code"
                                  required
                                  value={values.code}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  variant="outlined"
                                />}
                                <Button fullWidth
                                  onClick={() => handleDelete()}
                                  size="large"
                                  type="button"
                                  variant="contained"
                                  disabled={isUserActivityPresent && values.code !== administration.code}
                                  style={{ color: isUserActivityPresent && values.code !== administration.code ? 'gray' : 'red' }}>
                                  <FormattedMessage id="buttons.permanentDelete" />
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>

                    <Box my={2} mt={3}>
                      <DialogActions>
                        <Button onClick={handleClose} color="primary">
                          <FormattedMessage id="buttons.cancel" />
                        </Button>
                      </DialogActions>
                    </Box>
                  </form>
                )}
              </Formik>
            ) : null}
          </Grid>
        </Grid>
      </Container>
    </Dialog >
  )
}

export default injectIntl(DeleteAdministration)

DeleteAdministration.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  intl: PropTypes.object
}
